import React from "react";
//import dayjs from "dayjs";
import weatherIcons from "./icons.json";
//import './App.scss';

interface ForecastProps {
    forecast: any[];
    // bgDarker: string;
    // tileOpacity: string;
    cardTint: any;
}

export default function Forecast({ cardTint, forecast }: ForecastProps) {
    //console.log("tile opacity: ", tileOpacity, " bgcolor: ", bgDarker);
    const prefix = "wi wi-";
    const result = forecast.map((item, index) => {
        const icon = prefix + ((weatherIcons.day as any)[item.icon_id]?.icon);
        //console.log(item.icon_id);

        //console.log((weatherIcons.day as any)[item.icon_id]?.icon);

        const getDayOfWeek = (dateString: string): string => {
            const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            //const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
            const date = new Date(dateString);
            const dayOfWeek = days[date.getDay()];
            return dayOfWeek;
        }

        //console.log(getDayOfWeek(props.forecast[index].dt_txt));

        const theDay = getDayOfWeek(forecast[index].dt_txt);
        const description = item.description;

        console.log(item);

        return (
            // <div key={index} className="forecastItem" style={{ backgroundColor: "rgba(" + bgDarker + ", " + tileOpacity + ")" }}>
            <div key={index} className="forecastItem" style={{ backgroundColor: cardTint }}>
                {/* <div className="week-day">{dayjs(item.dt_txt).format("dddd")}</div> */}
                <div className="week-day">{theDay}</div>
                <div className="forecast-icon"><span className={icon}></span></div>
                <div className="day-description">{description}</div>
                <div className="temp">
                    <span>{Math.round(item.temperature)}<span className="wi wi-celsius"></span></span>
                    {/* <span>{Math.round(item.max)}&deg;</span> */}
                </div>
                {/* <div className="temp-min-max">
                    <span>min / max</span>
                </div> */}
            </div>
        );
    });

    return <div className="forecast">{result}</div>;
}