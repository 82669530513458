import { ReactComponent as EmailIcon } from "../Assets/generic/email.svg";
import { ReactComponent as LinkedinIcon } from "../Assets/generic/linkedin.svg";
import { ReactComponent as RefreshIcon } from "../Assets/generic/refresh.svg";

interface NavProps {
  changeColorsCallBack: any;
}

const TopNav = ({ changeColorsCallBack }: NavProps) => {
  
  return (
    <div className="toolbar">
            <div className="tb-left">
                <div><a href="mailto:theumabrian@gmail.com?Subject=Hello..." rel="noopener noreferrer" target="_blank">
                    <EmailIcon />
                </a></div>
                <div><a href="https://www.linkedin.com/pub/brian-theuma/7/311/472" rel="noopener noreferrer" target="_blank">
                    <LinkedinIcon />
                </a></div>
            </div>
            <div className="tb-right">
                <div className="change-color" onClick={() => changeColorsCallBack()}>
                    <span>change color</span>
                    <RefreshIcon />
                </div>
            </div>
            {/* <div className="curr-colors">{props.currRgb}<br/>#{props.currHex}</div> */}
        </div>
  );
}

export default TopNav;
