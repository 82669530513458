import './index.scss';

interface CoinProps {
    coinData: {
        eur_24h_change: number;
        eur: number;
    };
    coin: {
        title: string;
        short: string;
        image: string;
        totals: number;
    };
    showTotals: boolean;
    changeAmounts: (coinTitle: string) => void; // Define the callback function type
}

const Coin = ({ coinData, coin, showTotals, changeAmounts }: CoinProps) => {

    return (
        <div className={`coin-item`}>
            <div className={`coin ${coin.short}`}>
                <div className={`coin-inner`}>
                    <img src={coin.image} alt={coin.title} className='ico' onClick={() => changeAmounts(coin.title)} />
                    <div className={`title`}>{coin.title}</div>
                    <div className={`title_s`}>{coin.short} <span className={`count ${showTotals && 'show'}`} onClick={() => changeAmounts(coin.title)}>x{coin.totals.toFixed(2)}</span></div>
                    <div className={`price`}>€{new Intl.NumberFormat().format(coinData.eur)}</div>
                    <div className={`change ${Math.sign(coinData.eur_24h_change) === 1 ? 'up' : 'down'}`}>{coinData.eur_24h_change}%</div>
                </div>
            </div>
            <div className={`owned ${showTotals ? 'show' : 'hide'}`}>€{new Intl.NumberFormat().format(coinData.eur * coin.totals)}</div>
        </div>
    );
}

export default Coin;