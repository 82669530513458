import { Navigate } from "react-router-dom";
import Coins from "../Components/coins/coins";
import { useAuth0 } from "@auth0/auth0-react";

const Crypto = () => {

    const { isAuthenticated } = useAuth0();

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <div className="house page-crypto">
            <h1>Crypto</h1>
            <Coins />
        </div>
    );
}
export default Crypto;