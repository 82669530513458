import { useState, useEffect } from 'react';
import languages from "../Data/FlashCards/languages.json";

interface flashProps {
    bgDarker: string;
    bgLighter: string;
    themeTint: string;
}


const FlashCards = ({ themeTint, bgDarker, bgLighter }: flashProps) => {

    const ptEn = languages["pt-en-1"];
    const [deck, setDeck] = useState([]);
    const [currentCard, setCurrentCard] = useState<{ pt: string, en: string, pre: string } | null>(null);
    const [showAnswer, setShowAnswer] = useState(false);
    const [tileOpacity, setTileOpacity] = useState("0.57");
    const [learningLanguage, setLearningLanguage] = useState("pt");

    useEffect(() => {
        // Initialize deck with shuffled flashcards
        const shuffledDeck = shuffleArray([...ptEn]);
        setDeck(shuffledDeck);
        setCurrentCard(shuffledDeck[0]);
    }, []);

    const shuffleArray = (array: any) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const handleNext = () => {
        setShowAnswer(false);
        if (deck.length === 0) {
            // If deck is empty, reshuffle the cards
            const reshuffledDeck = shuffleArray([...ptEn]);
            setDeck(reshuffledDeck);
            setCurrentCard(reshuffledDeck[0]);
        } else {
            // Set the next card
            const nextCard = deck[0];
            setDeck(deck.slice(1));
            setCurrentCard(nextCard);
        }
    };

    const handleSwap = () => {
        setShowAnswer(false);
        if (learningLanguage === "pt") {
            setLearningLanguage("en");
        } else {
            setLearningLanguage("pt");
        }
    };

    const cardTint = () => {
        if (themeTint === "dark") {
            return "rgba(" + bgLighter + ", " + tileOpacity + ")";
        } else if (themeTint === "light") {
            return "rgba(" + bgDarker + ", " + tileOpacity + ")";
        }
    };

    return (
        <div className="house page-flashcards">
            {currentCard && (
                <div className='card' style={{ backgroundColor: cardTint() }}>
                    <h2 className='card-language'>{learningLanguage === "pt" ? currentCard.pt : currentCard.en}</h2>
                    <h3 className='card-language-pronounce'>( {learningLanguage === "pt" ? currentCard.pre : null} )</h3>
                    <h2 className='card-answer' style={{ display: showAnswer ? "block" : "none" }}>{learningLanguage === "pt" ? currentCard.en : currentCard.pt}</h2>
                </div>
            )}
            <button className='btn-show' onClick={() => setShowAnswer(!showAnswer)}>Show Answer</button>
            <button className='btn-next' onClick={handleNext}>Next Card</button>
            <div className='btn-swap' onClick={handleSwap}>Swap Learning Languages</div>
        </div>
    );
}
export default FlashCards;
