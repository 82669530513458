import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "../Components/LoginBtn";
import { useEffect } from "react";

const Login = () => {
    const { loginWithRedirect } = useAuth0();
    
    useEffect(() => {
        loginWithRedirect();
    }, [loginWithRedirect]);

    return (
        <div className="house page-login">
            <h1>Login</h1>
            Click the "Login" if the browser does not redirect you.<br /><br /><br />
            <LoginButton />
        </div>
    );
}
export default Login;