import React, { useState, useEffect } from 'react';
import workoutDataGPT from "../Data/Workouts/workouts-gpt.json";
import workoutDataPT from "../Data/Workouts/workouts-pt.json";
import './WorkoutPlan.scss';
import ExerciseDetail from '../Components/workouts/ExerciseDetail';

interface Exercise {
    exercise: string | null;
    reps: string | null;
    link: string | null;
}

interface Day {
    title: string;
    detail: string;
    coolDown: string | null;
    exercises: Exercise[] | null;
}

interface WorkoutData {
    [key: string]: Day;
}



const WorkoutPlan: React.FC = () => {
    const [selectedDay, setSelectedDay] = useState<string>('day1');
    const [instruction, setInstruction] = useState<string>('dumbbell bench press');
    const [workoutPlan, setWorkoutPlan] = useState<WorkoutData>(workoutDataPT);

    // Use useEffect to track changes to instruction
    useEffect(() => {
        console.log("Instruction updated:", instruction);
    }, [instruction]);

    const handleDayClick = (day: string) => {
        setSelectedDay(day);
    };

    const handleExerciseClick = (exerciseName: string) => {
        console.log("Clicked exercise:", exerciseName); // Check if the click is firing
        setInstruction(exerciseName.toLowerCase());  // Convert to lowercase before setting
    };

    const renderExercises = (exercises: Exercise[] | null) => {
        if (!exercises) {
            return null;
        }

        return (
            <ol>
                {exercises.map((exercise, index) => (
                    <li key={index}>
                        {/* Ensure handleExerciseClick is correctly invoked */}
                        {/* <div onClick={() => handleExerciseClick(exercise.exercise || '')}> */}
                        <a href={exercise.link ? exercise.link : undefined} target='_blank' rel="noreferrer">
                            {exercise.exercise}
                        </a>
                        <span>{exercise.reps}</span>
                    </li>
                ))}
            </ol>
        );
    };

    const selectedWorkout = workoutPlan[selectedDay];

    if (!selectedWorkout) {
        return <div>No data found for this day.</div>;
    }

    return (
        <div className="house page-workouts">
            <h1>Weekly Workout Plan</h1>
            <div className="workout-select">
                <div className={`ws-item ${workoutPlan === workoutDataPT ? 'active' : ''}`}
                    onClick={() => setWorkoutPlan(workoutDataPT)}>
                    Personal Trainer
                </div>
                <div className={`ws-item ${workoutPlan === workoutDataGPT ? 'active' : ''}`}
                    onClick={() => setWorkoutPlan(workoutDataGPT)}>
                    Chat GPT
                </div>
            </div>
            <div className="workout-nav">
                {Object.keys(workoutPlan).map((day, index) => (
                    <div
                        key={index}
                        onClick={() => handleDayClick(day)}
                        className={`wn-item ${selectedDay === day ? 'active' : ''}`}
                    >
                        {`Day ${index + 1}`}
                    </div>
                ))}
            </div>
            <div className="workout">
                <div className="workout-inner">
                    <h4>{selectedWorkout.title}</h4>
                    <p>{selectedWorkout.detail}</p>
                    {renderExercises(selectedWorkout.exercises)}
                    {selectedWorkout.coolDown && <p><strong>Cool Down:</strong> {selectedWorkout.coolDown}</p>}
                </div>
            </div>

            {/* Pass updated exerciseName to ExerciseDetail */}
            {/* <ExerciseDetail exerciseName={instruction} /> */}

        </div>
    );
};

export default WorkoutPlan;