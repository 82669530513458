import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as LogoutIcon } from "../Assets/generic/logout.svg";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Logout <LogoutIcon />
    </button>
  );
};

export default LogoutButton;