import { Navigate } from "react-router-dom";
import { ReactComponent as WashingMachine } from "../Assets/laundry/washing-machine.svg";
import { useAuth0 } from "@auth0/auth0-react";

const Laundry = () => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
      return <Navigate to="/" />;
  }

  return (
    <div className="house page-laundry">
      <h1>Laundry</h1>
      <div className="washing-machine">
        <WashingMachine className="washing-machine-svg" />
      </div>
      <div className="laundry-items">
        <div className="left">
          <div className="item"><span className="item-inner">15' Quick Wash</span></div>
          <div className="item"><span className="item-inner">Mixed Load</span></div>
          <div className="item"><span className="item-inner">Delicates</span></div>
          <div className="item"><span className="item-inner">Wool</span></div>
          <div className="item"><span className="item-inner">Intense Cold</span></div>
          <div className="item"><span className="item-inner">Colors</span></div>
          <div className="item"><span className="item-inner">Bedding</span></div>
        </div>
        <div className="right">
          <div className="item"><span className="item-inner">Eco 40 - 60</span></div>
          <div className="item"><span className="item-inner">Cotton</span></div>
          <div className="item"><span className="item-inner">Synthetics</span></div>
          <div className="item"><span className="item-inner">Hygiene Steam</span></div>
          <div className="item"><span className="item-inner">Rinse &amp; Spin</span></div>
          <div className="item"><span className="item-inner">Drain / Spin</span></div>
          <div className="item"><span className="item-inner">Drum Clean</span></div>
        </div>
      </div>
    </div>
  );
}

export default Laundry;
