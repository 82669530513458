import { ReactComponent as Organic } from "../Assets/trash/organic.svg";
import { ReactComponent as Mixed } from "../Assets/trash/mixed.svg";
import { ReactComponent as Recycle } from "../Assets/trash/recycle.svg";
import { ReactComponent as None } from "../Assets/trash/none.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

const Trash = () => {
    const { isAuthenticated } = useAuth0();

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    function getSvg(icon: string) {
        switch (icon) {
            case 'Monday': return <Organic className="trash-svg" />
            case 'Tuesday': return <Mixed className="trash-svg" />
            case 'Wednesday': return <Organic className="trash-svg" />
            case 'Thursday': return <Recycle className="trash-svg" />
            case 'Friday': return <Organic className="trash-svg" />
            case 'Saturday': return <Mixed className="trash-svg" />
            case 'Sunday': return <None className="trash-svg" />
            default: return null;
        }
    }

    // Create a new Date object
    const currentDate = new Date();

    // Get the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    const dayOfWeek = currentDate.getDay();

    // Define an array of day names
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Get the name of the day using the day of the week obtained from the Date object
    const dayName = daysOfWeek[dayOfWeek];
    const dayTomorrow = daysOfWeek[(dayOfWeek + 1) % daysOfWeek.length];
    const dayAfterTomorrow = daysOfWeek[(dayOfWeek + 2) % daysOfWeek.length];

    return (
        <div className="house page-trash">
            <h1>Trash</h1>
            <div className="days">
                <div className="today">
                    <div className="day">
                        <div className="day-inner">
                            <h2>
                                <span>today</span>
                                {dayName}
                            </h2>
                            <div className="day-svg">
                                {getSvg(dayName)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="comings">
                    <h3>coming up</h3>
                    <div className="coming">
                        <div className="day">
                            <div className="day-inner">
                                <h2>{dayTomorrow}</h2>
                                <div className="day-svg">
                                    {getSvg(dayTomorrow)}
                                </div>
                            </div>
                        </div>
                        <div className="day">
                            <div className="day-inner">
                                <h2>{dayAfterTomorrow}</h2>
                                <div className="day-svg">
                                    {getSvg(dayAfterTomorrow)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Trash;
