import { Link } from "react-router-dom";

const Menu = () => {
    return (
        <div>
            <Link to="/">Home</Link>
            <br />
            <Link to="/trash">Trash</Link>
            <br />
            <Link to="/laundry">Laundry</Link>
            <br />
            <Link to="/crypto">Crypto</Link>
        </div>
    );
}
export default Menu;
