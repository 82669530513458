import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import "./App.scss";

//import { ReactComponent as HomeIcon } from "./Assets/generic/home.svg";
import { ReactComponent as CloseIcon } from "./Assets/generic/close.svg";
import { ReactComponent as LaundryIcon } from "./Assets/laundry/laundry.svg";
import { ReactComponent as TrashIcon } from "./Assets/trash/trash.svg";
import { ReactComponent as CryptoIcon } from "./Assets/crypto/crypto.svg";
//import { ReactComponent as RefreshIcon } from "./Assets/generic/refresh.svg";
import { ReactComponent as HamburgerIcon } from "./Assets/generic/hamburger.svg";

import Home from "./Pages/Home";
import Menu from "./Pages/Menu";
import Laundry from "./Pages/Laundry";
import Crypto from "./Pages/Crypto";
import Trash from "./Pages/Trash";
import WorkoutPlan from "./Pages/WorkoutPlan";
import Login from "./Pages/Login";
import FlashCards from "./Pages/FlashCards";
import LogoutButton from "./Components/LogOutBtn";
import { useEffect, useRef, useState } from "react";
import TopNav from "./Components/TopNav";
import AbstractBackground from "./Components/AbstractBackground";


export default function App() {

  const [theTheme, setTheTheme] = useState({
    bgColor: "",
    hexColor: "",
    txtColor: "",
    tintTheme: "",
    bgPattern: 1
  });



  const setTheme = (theme: { bgColor: string, hexColor: string, txtColor: string, tintTheme: string, bgPattern: number }) => {
    setTheTheme({
      bgColor: theme.bgColor,
      hexColor: theme.hexColor,
      txtColor: theme.txtColor,
      tintTheme: theme.tintTheme,
      bgPattern: theme.bgPattern
    });
  };

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  const [bgColor, setBgColor] = useState("");
  const [bgColorDarker, setBgColorDarker] = useState("");
  const [bgColorLighter, setBgColorLighter] = useState("");
  const [bgPattern, setBGPattern] = useState(1);
  const [hexColor, setHexColor] = useState("");
  const [txtColor, setTxtColor] = useState("");
  const [tintTheme, setTintTheme] = useState("");
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      random_bg_color();
      setTheme({ tintTheme, bgColor, txtColor, hexColor, bgPattern });
      isInitialMount.current = false;
    } else {
      lightOrDark(bgColor);
    }
  });

  useEffect(() => {
    lightOrDark(bgColor);
    setTheme({ tintTheme, bgColor, txtColor, hexColor, bgPattern });
  },[bgColor, tintTheme, txtColor, hexColor, bgPattern]);

  function random_bg_color() {
    var r = Math.floor(Math.random() * (256 - 0) + 0);
    var g = Math.floor(Math.random() * (256 - 0) + 0);
    var b = Math.floor(Math.random() * (256 - 0) + 0);
    rgbToHex(r, g, b);
    setBgColor("rgb(" + r + "," + g + "," + b + ")");
    setBgColorDarker((r - 16) + "," + (g - 16) + "," + (b - 16));
    setBgColorLighter((r + 16) + "," + (g + 16) + "," + (b + 16));
    setBGPattern((Math.floor(Math.random() * 4) + 1));
  }

  function changeColors() {
    random_bg_color()
    lightOrDark(bgColor);
    setTheme({ tintTheme : tintTheme, bgColor: bgColor, txtColor: txtColor, hexColor: hexColor, bgPattern: bgPattern});
  }

  function rgbToHexOne(rgb: any) {
    let hex = Number(rgb).toString(16);
    if (hex.length < 2) {
      hex = "0" + hex;
    }
    return hex;
  };

  function rgbToHex(r: any, g: any, b: any) {
    let red = rgbToHexOne(r);
    let green = rgbToHexOne(g);
    let blue = rgbToHexOne(b);
    setHexColor(red + green + blue);
    //return red+green+blue;
  };

  function lightOrDark(color: any) {

    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    }
    else {

      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = color >> (8 & 255);
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      setTxtColor('#333');
      setTintTheme('dark');
      return 'dark';
    }
    else {
      setTxtColor('#ddd')
      setTintTheme('light');
      return 'light';
    }
  }
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  return (
    <Auth0Provider
      domain="brianwik.eu.auth0.com"
      clientId="ldgfMReEohJzmzms24ahtzHhNBmcAufI"
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
    >
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/" element={<Layout changeColors={changeColors} inTheTheme={theTheme} />}>
              <Route index element={<Home bgDarker={bgColorDarker} bgLighter={bgColorLighter} changeColorsCallBack={changeColors} themeTint={tintTheme} />} />
              <Route path="/login" element={<Login />} />
              <Route path="menu" element={<Menu />} />
              <Route path="laundry" element={<Laundry />} />
              <Route path="crypto" element={<Crypto />} />
              <Route path="trash" element={<Trash />} />
              <Route path="flashCards" element={<FlashCards bgDarker={bgColorDarker} bgLighter={bgColorLighter} themeTint={tintTheme} />} />
              <Route path="workout-plan" element={<WorkoutPlan />} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </Auth0Provider>
  );
}

function Layout({changeColors, inTheTheme}: any) {
  const { isAuthenticated } = useAuth0();
  
  const [internalMenu, setInternalMenu] = useState(false);

  console.log('inTheTheme');
  console.log(inTheTheme);
  console.log('inTheTheme');
  

  return (
    // <div className={`mainPage`} style={{ backgroundColor: inTheTheme.bgColor, color: inTheTheme.txtColor, backgroundImage: 'url("/assets/patterns/pattern-'+ inTheTheme.bgPattern +'-'+ inTheTheme.tintTheme +'.svg")'}}>
    <div className={`mainPage`} style={{ backgroundColor: inTheTheme.bgColor, color: inTheTheme.txtColor}}>
      <div className="mainPage-bg" style={{ backgroundColor: inTheTheme.bgColor, color: inTheTheme.txtColor }}></div>
      <div className="curr-colors">
        <div>{inTheTheme.bgColor}</div>
        <div>#{inTheTheme.hexColor}</div>
      </div>
      {/* <AbstractBackground width={window.innerWidth} height={window.innerHeight} color={inTheTheme.txtColor} radius={0} bgColor={inTheTheme.bgColor} /> */}
      <AbstractBackground theClass={inTheTheme.tintTheme} />
      
      <div className="vignette"></div>
      <div className="page-content">
        <TopNav changeColorsCallBack={changeColors} />
        {isAuthenticated && (
          <div className="internal-menu">
            {/* <div onClick={() => setInternalMenu(!internalMenu)} className="menu-icon"><HomeIcon /></div> */}
            {/* <div className="menu-icon"><Link onClick={() => changeColors()} to="/"><HomeIcon /></Link></div> */}
            {/* <div className="menu-icon"><Link onClick={() => changeColors()} to="/laundry"><LaundryIcon /></Link></div>
            <div className="menu-icon"><Link onClick={() => changeColors()} to="/trash"><TrashIcon /></Link></div>
            <div className="menu-icon"><Link onClick={() => changeColors()} to="/crypto"><CryptoIcon /></Link></div>
            <div className="menu-icon"><Link onClick={() => changeColors()} to="/flashCards">F</Link></div>
            <div className="menu-icon"><Link onClick={() => changeColors()} to="/workout-plan">WP</Link></div> */}
            {/* <div className="menu-icon" onClick={() => changeColors()}><RefreshIcon /></div> */}
            <div className="menu-icon" onClick={() => setInternalMenu(!internalMenu)}><HamburgerIcon /></div>
            {/* <LogoutButton /> */}
            <div className={`internal-menu-pages ${internalMenu ? 'active' : ''}`}>
              <div onClick={() => setInternalMenu(false)} className="close-icon"><CloseIcon /></div>
              {/* <Link onClick={() => changeColors()} to="/menu">Menu</Link> */}
              <Link onClick={() => {setInternalMenu(false); changeColors()}} to="/">Home</Link>
              <Link onClick={() => {setInternalMenu(false); changeColors()}} to="/laundry">Laundry</Link>
              <Link onClick={() => {setInternalMenu(false); changeColors()}} to="/crypto">Crypto</Link>
              <Link onClick={() => {setInternalMenu(false); changeColors()}} to="/trash">Trash</Link>
              <Link onClick={() => {setInternalMenu(false); changeColors()}} to="/flashCards">FlashCards</Link>
              <Link onClick={() => {setInternalMenu(false); changeColors()}} to="/workout-plan">Workout Plan</Link>
              <LogoutButton />
            </div>
          </div>
        )}
        <Outlet />
      </div>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p><Link to="/">Go to the home page</Link></p>
    </div>
  );
}
