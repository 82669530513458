import { useEffect, useState } from "react";
import axios from 'axios';
import './index.scss';
import Coin from "../coin";
import FormFields from "../FormFields";

function Coins() {

    const [showTotals, setShowTotals] = useState(false);
    const [showPop, setShowPop] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [coins] = useState(["bitcoin", "ethereum", "nexo"]);
    const [coinEdit, setCoinEdit] = useState('');
    const [thePortfolio, setThePortfolio] = useState<any>({});

    useEffect(() => {
        if (!localStorage.getItem('portfolio')) {
            localStorage.setItem('portfolio', JSON.stringify({ bitcoin: [0.433065, 0.17916513, 0.028], ethereum: [6.1013, 0.49], nexo: [1155.90] }));
        } else {
            let parsed = JSON.parse(localStorage.getItem('portfolio') || '{}');
            setThePortfolio(parsed);
        }
    }, []);

    useEffect(() => {
        //setCoins(["bitcoin", "ethereum", "nexo"]);
        const fetchData = async () => {
            const result = await axios.get(
                `https://api.coingecko.com/api/v3/coins/markets?vs_currency=eur&ids=${coins.join(',')}&order=market_cap_desc&sparkline=false&price_change_percentage=24h`
            );
            setData(result.data);
        };

        fetchData();
    }, [coins]);

    const changeAmountCallback = (coinTitle: string) => {
        setShowPop(!showPop);
        setCoinEdit(coinTitle);
        Object.keys(thePortfolio).map(key => {
            if (key === coinTitle.toLowerCase()) {
                console.log('to edit: ', thePortfolio[key]);
            }
            return null; // Add a return statement here
        })
    }

    const portfolioTotals: { [key: string]: number } = {};

    for (const coin in thePortfolio) {
        portfolioTotals[coin] = thePortfolio[coin].reduce((a: number, b: number) => a + b, 0);
    }

    const bigTotal = data.reduce((total, coin) => total + (coin.current_price * portfolioTotals[coin.id]), 0);

    const mapCoins = data.map(coin => (
        <div key={coin.id}>
            <Coin coinData={{ eur: coin.current_price, eur_24h_change: coin.price_change_percentage_24h_in_currency.toFixed(1) }} coin={{ title: coin.name, short: coin.symbol, image: coin.image, totals: portfolioTotals[coin.id] }} showTotals={showTotals} changeAmounts={changeAmountCallback} />
        </div>
    ));



    const handleSubmit = () => {
        localStorage.setItem('portfolio', JSON.stringify(thePortfolio));
        if(!coinEdit){
            console.log('add coin');
        }
        setShowPop(!showPop);
        setCoinEdit('');
    }

    const handleCancel = () => {
        //portfolio = thePortfolio;
        setThePortfolio(JSON.parse(localStorage.getItem('portfolio') || '{}'));
        //console.log('portfolio: ', portfolio);
        console.log('thePortfolio parent: ', thePortfolio);

        setShowPop(!showPop);
        setCoinEdit('');
    }



    return (
        <div className="wallet">
            <div className="show-hide" onClick={() => setShowTotals(!showTotals)}>show/hide</div>
            <h2 className={`${showTotals && 'show'}`}>€{new Intl.NumberFormat().format(bigTotal)}</h2>
            <div className="coins">
                {mapCoins}
            </div>
            <div className={`btn btn-primary pop ${showTotals && 'showing-totals'}`} onClick={() => setShowPop(!showPop)}>add a coin</div>
            <div className={`pop-form ${showPop && 'show'}`}>
                <div>
                    <h3>{!coinEdit ? 'Add Coin' : coinEdit}</h3>
                    <FormFields thePortfolio={thePortfolio} coinEdit={coinEdit.toLowerCase()} />
                    <div className="pop-form-footer">
                        <div className="btn pop-form--close" onClick={() => handleCancel()}>Cancel</div>
                        <div className="btn btn-primary" onClick={() => handleSubmit()}>{!coinEdit ? 'Add' : 'Save'}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Coins;