import { useEffect, useState, useLayoutEffect } from "react";

import './Home.scss';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import moment from 'moment';
import { ReactComponent as HeartIcon } from "../Assets/generic/heart.svg";
import { ReactComponent as CodeIcon } from "../Assets/generic/code.svg";
import { ReactComponent as PencilIcon } from "../Assets/generic/pencil.svg";
import { ReactComponent as CameraIcon } from "../Assets/generic/camera.svg";
import WeatherCard from "../Components/weather/WeatherCard";

interface HomeProps {
  changeColorsCallBack: any;
  themeTint: string;
  bgDarker: string;
  bgLighter: string;
}

const Home = ({bgDarker, bgLighter, changeColorsCallBack, themeTint}:HomeProps) => {

  const [navDot, setNavDot] = useState<string>('brian');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isLandscape, setIsLandscape] = useState(true);

  useEffect(() => {
    if (windowHeight > windowWidth) {
      setIsLandscape(false);
    } else {
      setIsLandscape(true);
    }
  }, [windowWidth, windowHeight]);

  useLayoutEffect(() => {
    function updateSize() {
      //setTheWindowSize([window.innerWidth, window.innerHeight]);
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust threshold as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setNavDot(entry.target.id);
        }
      });
    }, options);

    // Observe the target elements
    const brianElement = document.getElementById('brian');
    const passionElement = document.getElementById('passion');
    const weatherElement = document.getElementById('weather');

    if (brianElement) observer.observe(brianElement);
    if (passionElement) observer.observe(passionElement);
    if (weatherElement) observer.observe(weatherElement);

    return () => {
      // Cleanup
      if (brianElement) observer.unobserve(brianElement);
      if (passionElement) observer.unobserve(passionElement);
      if (weatherElement) observer.unobserve(weatherElement);
    };
  }, []);

  function navHit(whatLink: any) {
    setNavDot(whatLink);
    changeColorsCallBack();
    return;
  }

  function generateGreetings() {

    var currentHour = moment().format("HH");

    const hour = Number(currentHour);
    if (hour >= 3 && hour < 12) {
      return "good morning, I'm,";
    } else if (hour >= 12 && hour < 15) {
      return "good afternoon, I'm,";
    } else if (hour >= 15 && hour < 20) {
      return "good afternoon, I'm,";
    } else if (hour >= 20 || hour < 3) {
      return "good evening, I'm,";
    } else {
      return "Hello, I'm,";
    }
  }




  return (
    <>
      <div className={`page-nav ${themeTint}`}>
        <AnchorLink className={navDot === 'brian' ? 'dot-active ' + themeTint : ''} href='#brian' onClick={() => navHit('brian')}></AnchorLink>
        <AnchorLink className={navDot === 'passion' ? 'dot-active ' + themeTint : ''} href='#passion' onClick={() => navHit('passion')}></AnchorLink>
        <AnchorLink className={navDot === 'weather' ? 'dot-active ' + themeTint : ''} href='#weather' onClick={() => navHit('weather')}></AnchorLink>
      </div>
      <div className="card page-1" id="brian">
        <div className={"holder name-holder" + (isLandscape ? ' landscape' : ' portrait')}>
          <h3 className="greeting">{generateGreetings()}</h3>
          <h1 className="name" onClick={() => changeColorsCallBack()}>
            <span className="name-first">brian</span>
            <span className="name-space">&nbsp;</span>
            <span className="name-last">theuma</span>
          </h1>
          <h4 className="role">
            <div className="role-container">
              <span className="role-bracket rb-left">[</span>a ux/ui designer and an interaction developer<span className="role-bracket rb-right">]</span>
            </div>
          </h4>
        </div>
      </div>
      <div className="card page-2" id="passion">
        <div className={"holder page-2-holder" + (isLandscape ? ' landscape' : ' portrait')}>
          <div className="page-2-content">

            <div className="tagline">My <strong>passion</strong> is<br /><strong>to create</strong> awesome<br /><strong>interactive</strong> things<br />via <strong>design</strong> and <strong>code</strong>,<br />some with <strong>pen on paper</strong>,<br />and some with the<br /><strong>lense of a camera</strong></div>
            <div className="tagline-icons">
              <HeartIcon />
              <CodeIcon />
              <PencilIcon />
              <CameraIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="card page-2" id="weather">
        <div className={"holder page-2-holder" + (isLandscape ? ' landscape' : ' portrait')}>
          <WeatherCard bgDarker={bgDarker} bgLighter={bgLighter} themeTint={themeTint} />
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default Home;