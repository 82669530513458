import React, { useState, useEffect } from 'react';
import './index.scss';

interface CoinData {
  id: string;
  name: string;
  symbol: string;
}

interface CoinDataProps {
  coinNameCallback: (coinName: string) => void;
}

const SearchSelect = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState<CoinData[]>([]);
  const [selectedOption, setSelectedOption] = useState<CoinData | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    // Fetch data from the API endpoint
    const fetchData = async () => {
      try {
        const response = await fetch(`https://api.coingecko.com/api/v3/coins/list`);
        if (response.ok) {
          const data = await response.json();
          setOptions(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSelectOption = (option: CoinData) => {
    setSelectedOption(option);
    console.log(option.name);
    // coinNameCallback(option.name);
    setIsDropdownOpen(false);
  };

  const filteredOptions = options.filter(option =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    option.symbol.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="search-select">
      <div className="dropdown">
        <div className="dropdown-toggle input" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          {selectedOption ? `${selectedOption.name} (${selectedOption.symbol})` : 'Select an option'}
        </div>
        {isDropdownOpen && (
          <div className='dropdown-content input'>
            <input
              type="text"
              className="search-input input"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="dropdown-menu">
              {filteredOptions.map(option => (
                <div
                  key={option.id}
                  className="dropdown-item"
                  onClick={() => handleSelectOption(option)}
                >
                  {option.name} ({option.symbol})
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchSelect;
