import { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../Assets/generic/close.svg";
import SearchSelect from "../SearchSelect";

interface FormProps {
    coinEdit: string;
    thePortfolio: any;
}

const FormFields = ({ thePortfolio, coinEdit }: FormProps) => {
    const [showField, setShowField] = useState(true);
    const [coinAmounts, setCoinAmounts] = useState<any[]>([]);

    useEffect(() => {
        if (coinEdit && thePortfolio) {
            const coinAmountsLowerCase = thePortfolio[coinEdit.toLowerCase()];
            if (coinAmountsLowerCase) {
                setCoinAmounts(coinAmountsLowerCase);
            }
        }
    }, [coinEdit, thePortfolio]);

    const updatePortfolio = (e: any, index: number) => {
        const value = Number(e.target.value);
        const updatedCoinAmounts = [...coinAmounts];
        updatedCoinAmounts[index] = value;
        thePortfolio[coinEdit.toLowerCase()] = updatedCoinAmounts;
        setCoinAmounts(updatedCoinAmounts);
    }

    const handleRemoveAmount = (index: any) => {
        // Filter out the item to be removed
        const updatedList = coinAmounts.filter((_item: any, i: number) => i !== index);
        // Update the state with the new array
        thePortfolio[coinEdit.toLowerCase()] = updatedList;
        setCoinAmounts(updatedList);
        setShowField(!showField);
    }

    const addMore = () => {
        setCoinAmounts([...coinAmounts, '']);
        setShowField(!showField);
    }

    const handleAddCoin = (coinToAdd:string, amountToAdd:Number) => {
        // Add the coin to the portfolio
        console.log('adding: ', coinToAdd, ' -- ' , amountToAdd);
        thePortfolio[coinToAdd.toLowerCase()] = amountToAdd;

        // thePortfolio[coinEdit.toLowerCase()] = coinAmounts;
        // setCoinAmounts([]);
        // setShowField(!showField);
    }

    const amountChanged = () => {
        console.log('amount changed');
    };

    return (
        <div>
            {!coinEdit &&
                <>
                    <SearchSelect />
                    
                    {/* <input className="input" type="text" name="coinName" placeholder="Coin Name" /> */}
                </>
            }
            {coinEdit ?
                <>
                    {coinAmounts && coinAmounts.map((amount: any, index: number) => {
                        return (
                            <div key={index} className={`amount-input`}>
                                <input
                                    className="input"
                                    type="text"
                                    name="coinAmount"
                                    placeholder="Coin Amount"
                                    value={amount}
                                    onChange={(e) => updatePortfolio(e, index)}
                                />
                                <div className="btn btn-danger delete-amount" onClick={() => handleRemoveAmount(index)}>
                                    <CloseIcon />
                                </div>
                            </div>
                        );
                    })}
                    {coinEdit && <div className="btn btn-secondary" onClick={() => addMore()}>add another amount</div>}
                </>
                :
                <input className="input" type="text" name="coinAmount" placeholder="Coin Amount" onChange={() => amountChanged()} />
            }

        </div>
    );
}

export default FormFields;
