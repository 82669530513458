import axios from "axios";
import { useEffect, useState } from "react";
import Forecast from "./forecast";
import weatherIcons from "./icons.json";
import './WeatherCard.scss';
import moment from "moment";

interface WeatherCard {
    bgDarker: string;
    bgLighter: string;
    themeTint: string;
}

const WeatherCard = ({ themeTint, bgDarker, bgLighter }: WeatherCard) => {
    // State to store weather data
    const [weatherData, setWeatherData] = useState<any>(null);
    // State to track loading status
    const [loading, setLoading] = useState<boolean>(true);

    //const icon = "wi wi-" + ((weatherIcons.day as any)[weatherData.weather[0].id].icon);
    const [currIcon, setCurrIcon] = useState<any>(null);
    //console.log('icon is:', icon);


    function timeOfDay() {

        var currentHour = moment().format("HH");

        const hour = Number(currentHour);
        if (hour >= 3 && hour < 12) {
            return "good morning, I'm,";
        } else if (hour >= 12 && hour < 15) {
            return "good afternoon, I'm,";
        } else if (hour >= 15 && hour < 20) {
            return "good afternoon, I'm,";
        } else if (hour >= 20 || hour < 3) {
            return "good evening, I'm,";
        } else {
            return "Hello, I'm,";
        }
    }


    // Function to fetch weather data
    const fetchWeatherData = async () => {
        try {
            const response = await axios.get('https://api.openweathermap.org/data/2.5/weather', {
                params: {
                    q: 'Naxxar',
                    units: 'metric',
                    APPID: '957e1bb0bdb2df6f75ca0a35a8282c74'
                }
            });
            // Set weather data
            setWeatherData(response.data);
            //console.log('weatherData:', response.data);
            setCurrIcon("wi wi-" + ((weatherIcons.day as any)[response.data.weather[0].id].icon));

            // Set loading to false
            setLoading(false);
        } catch (error) {
            console.error('Error fetching weather data:', error);
            // Set loading to false in case of error
            setLoading(false);
        }
    };

    const handleResponse = (response: any) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error("Error: Location " + response.statusText);
        }
    }


    const getForecast = (city: string) => {
        return fetch(
            `${process.env.REACT_APP_API_URL}/forecast/?q=${city}&units=metric&APPID=${process.env.REACT_APP_API_KEY}`
        )
            .then(res => handleResponse(res))
            .then(result => {
                // console.log('-----------');
                // console.log('result to push to forecast is: ', result);
                // console.log('-----------');
                if (Object.entries(result).length) {
                    const forecast: any[] | PromiseLike<any[] | undefined> | undefined = [];
                    for (let i = 0; i < result.list.length; i += 8) {
                        forecast.push(mapDataToWeatherInterface(result.list[i + 4]));
                    }
                    //console.log(result);
                    return forecast;
                }
            });
    }

    const mapDataToWeatherInterface = (data: any) => {
        console.log('data is: ', data)
        const mapped: any = {
            //city: data.name,
            //country: data.sys.country,
            date: data.dt * 1000,
            humidity: data.main.humidity,
            icon_id: data.weather[0].id,
            temperature: data.main.temp,
            description: data.weather[0].description,
            wind_speed: Math.round(data.wind.speed * 3.6), // convert from m/s to km/h
            //condition: data.cod,
            dt_txt: data.dt_txt,
            icon: data.weather[0].icon,
            min: data.main.temp_min,
            max: data.main.temp_max
        };

        // Add extra properties for the five day forecast: dt_txt, icon, min, max
        if (data.dt_txt) {
            mapped.dt_txt = data.dt_txt;
        }

        if (data.weather[0].icon) {
            mapped.icon = data.weather[0].icon;
        }

        if (data.main.temp_min && data.main.temp_max) {
            mapped.max = data.main.temp_max;
            mapped.min = data.main.temp_min;
        }

        // remove undefined fields
        Object.keys(mapped).forEach(
            key => mapped[key] === undefined && delete data[key]
        );

        return mapped;
    }

    // Fetch weather data on component mount
    const [city, setCity] = useState("Naxxar");
    const [error, setError] = useState(null);
    //const [currentWeather, setCurrentWeather] = useState({});
    const [forecast, setForecast] = useState([]);
    const [sunRise, setSunRise] = useState('');
    const [sunSet, setSunSet] = useState('');
    const [tileOpacity, setTileOpacity] = useState("0.25");
    //const [bgColor, setBgColor] = useState("")
    //const [hexColor, setHexColor] = useState("")
    //const [txtColor, setTxtColor] = useState("")
    //const [tintTheme, setTintTheme] = useState("")
    //const [navDot, setNavDot] = useState("brian")
    //const [brianY, setBrianY] = useState()
    //const [passionY, setPassionY] = useState()
    //const [weatherY, setWeatherY] = useState()
    //const isInitialMount = useRef(true);
    //const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    //const [isLandscape, setIsLandscape] = useState(true);

    useEffect(() => {
        getForecast(city)
            .then((data: any) => {
                setForecast(data);


                const sunRiseDate = new Date(weatherData.sys.sunrise * 1000);
                const sunSetDate = new Date(weatherData.sys.sunset * 1000);
                const sunRise = sunRiseDate.getHours().toString().padStart(2, '0') + ':' + sunRiseDate.getMinutes().toString().padStart(2, '0');;
                const sunSet = sunSetDate.getHours().toString().padStart(2, '0') + ':' + sunSetDate.getMinutes().toString().padStart(2, '0');
                setSunRise(sunRise);
                setSunSet(sunSet);


                setError(null);
            })
            .catch(err => {
                console.error(`Error fetching forecast for ${city}:`, error);
                setError(err.message);
            });
    }, [city, error]);

    useEffect(() => {
        fetchWeatherData();
    }, []);

    // useEffect(() => {
    //     getForecast(city)
    //         .then((data: any) => {
    //             setForecast(data);
    //             setError(null);
    //         })
    //         .catch(err => {
    //             console.error(`Error fetching forecast for ${city}:`, error);
    //             setError(err.message);
    //         });
    // }, [city, error]);

    // Render weather data
    //console.log('weatherdata: ',weatherData.sys.sunrise);

    function calculateBeaufort(windSpeed: any) {
        if (windSpeed < 0.3) {
            return 0;
        } else if (windSpeed >= 0.3 && windSpeed <= 1.5) {
            return 1;
        } else if (windSpeed <= 3.3) {
            return 2;
        } else if (windSpeed <= 5.4) {
            return 3;
        } else if (windSpeed <= 7.9) {
            return 4;
        } else if (windSpeed <= 10.7) {
            return 5;
        } else if (windSpeed <= 13.8) {
            return 6;
        } else if (windSpeed <= 17.1) {
            return 7;
        } else if (windSpeed <= 20.7) {
            return 8;
        } else if (windSpeed <= 24.4) {
            return 9;
        } else if (windSpeed <= 28.4) {
            return 10;
        } else if (windSpeed <= 32.6) {
            return 11;
        } else {
            return 12;
        }
    }

    const cardTint = () => {
        if (themeTint === "dark") {
            return "rgba(" + bgLighter + ", " + tileOpacity + ")";
        } else if (themeTint === "light") {
            return "rgba(" + bgDarker + ", " + tileOpacity + ")";
        }
    }

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    <div className="section">
                        <div className="title">The weather where I'm at is:</div>
                        {/* <div className="current-weather" style={{ backgroundColor: 'rgba(' + bgDarker + ", " + tileOpacity + ")" }}> */}
                        <div className="current-weather" style={{ backgroundColor: cardTint() }}>
                            <div className={`cw-weather-top`}>
                                <div className="cw-description">{weatherData.weather[0].description}</div>
                                <div className="cw-temp">{Math.round(weatherData.main.temp)}&deg;<span className="celsius">C</span></div>
                                <div className={`cw-weather-icon ${currIcon}`}></div>
                            </div>
                            <div className="cw-temp-items">

                                <div className="cw-sun-item">
                                    <span className="wi wi-sunrise"></span><span>{sunRise}</span>
                                </div>
                                <div className="cw-sun-item">
                                    <span className="wi wi-sunset"></span><span>{sunSet}</span>
                                </div>
                                {/* <span className="wi wi-celsius"></span> */}



                                <div className="cw-wind-item">
                                    <div className={`wi wi-wind towards-${weatherData.wind.deg}-deg`}></div>
                                    <div>
                                        {weatherData.wind.deg}&deg; {weatherData.wind.deg > 0 && weatherData.wind.deg < 90 ? 'NE' : ''}
                                        {weatherData.wind.deg > 90 && weatherData.wind.deg < 180 ? 'SE' : ''}
                                        {weatherData.wind.deg > 180 && weatherData.wind.deg < 270 ? 'SW' : ''}
                                        {weatherData.wind.deg > 270 && weatherData.wind.deg < 360 ? 'NW' : ''}
                                    </div>
                                </div>
                                <div className="cw-wind-item">
                                    <span className={`wi wi-wind-beaufort-${calculateBeaufort(weatherData.wind.speed)}`}></span>
                                    <span>{Math.round(weatherData.wind.speed)} m/s</span>
                                </div>

                                {/* <div>Humidity: {weatherData.main.humidity}%</div> */}
                            </div>
                        </div>
                        {/* Add more weather data as needed */}
                    </div>
                    <div className="section">
                        <Forecast forecast={forecast} cardTint={cardTint()} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default WeatherCard;