import { useEffect, useState } from "react";

// interface AbstractBackgroundProps {
//     width: number;
//     height: number;
//     color: string;
//     radius: number;
//     bgColor: string;
// }

interface AbstractBackgroundProps {
    theClass: string;
}

// const AbstractBackground: React.FC<AbstractBackgroundProps> = ({ width, height, color, radius, bgColor }) => {
const AbstractBackground = ({theClass}:AbstractBackgroundProps) => {

    const [delayedClassName, setDelayedClassName] = useState(`abstract-bg abstract-base ${theClass}`);

    const width = 1920;
    const height =  1080;
    const color =  "white";
    const radius =  5;
    const bgColor =  'white';

    console.log('theClass',theClass)

    useEffect(() => {

        // Set a delay before adding the classname
        //setDelayedClassName(`abstract-bg abstract-base ${theClass}`);
        setDelayedClassName(`abstract-bg abstract-bg-fade-in ${theClass}`);
        const delayTimeout = setTimeout(() => {
            //setDelayedClassName(`abstract-bg abstract-bg-fade-in ${theClass}`);

        }, 1000); // Adjust the delay time as needed (1000ms = 1 second)

        // Cleanup function to clear the timeout
        return () => clearTimeout(delayTimeout);
    }, [theClass]);

    // Function to generate random number within a range
    const getRandomNumber = (min: number, max: number) => {
        return Math.random() * (max - min) + min;
    };

    // Generate SVG paths dynamically
    const generatePaths = () => {
        const paths = [];
        // Generate a random number of shapes (rectangles in this case)
        //const numShapes = Math.floor(getRandomNumber(100, 1000));
        const numShapes = 100;
        for (let i = 0; i < numShapes; i++) {
            const x = getRandomNumber(0, width);
            const y = getRandomNumber(0, height);
            const widthRect = getRandomNumber(10, width / 2);
            const heightRect = getRandomNumber(10, height / 2);
            paths.push(
                <>
                    <circle
                        key={i}
                        cx={x}
                        cy={y}
                        width={widthRect}
                        height={heightRect}
                        r={Math.floor(Math.random() * 10) + 1}
                        //stroke={color}
                        strokeWidth={Math.floor(Math.random() * 5) + 1}
                        opacity={Math.random() * 0.1}
                        // fill={color}
                    />
                </>
            );
        }
        return paths;
    };

    return (
        <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" className={delayedClassName}>
            {/* Call the function to generate paths */}
            {generatePaths()}
        </svg>
    );
};

export default AbstractBackground;